@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body, p, h1, h2, h3, h4, h5, span{font-family: 'PT Sans Caption', sans-serif;}
h1, h2, h3, h4, h5{font-weight:bold;}
th{text-align:left !important; padding:5px 20px;}
td{text-align:left !important; padding:5px 20px;}
a{color:#063d3f !important;}

html{overflow-x:hidden;}

.clearfix{clear:both;}

.error-text{color:#ff0000;}

.weeks-table{background-color:#ffffff;}
.inputHidden{display:none;}
.product-information{cursor:zoom-in}

.no-arrows input::-webkit-outer-spin-button, .no-arrows input::-webkit-inner-spin-button{-webkit-appearance:none;}

.no-arrows[type=number]{
  -moz-appearance: textfield;
}

.content{padding:20px;}



.show-sodium, .show-carbs, .show-fluids, .show-questions{display:block;}
.hide-sodium, .hide-carbs, .hide-fluids, .hide-questions{display:none;}


/* Weeks Blur */
.upcoming-week{filter:blur(6px)}
.upcoming-week input[type='number']{display:none;}
.past-week{display:none;}
/* Color Changes for Gut Training */
/* Carb Changes */
.carb-actual-five svg{font-weight:800; font-size:18px;  color:#FFE047;}
.carb-actual-ten svg {font-weight:800; font-size:18px;  color:#FF483B;}
.carb-actual-ok svg {font-weight:800; font-size:18px;  color:#61FFA3;}
/* Sodium Changes */
.sodium-actual-five svg{font-weight:800; font-size:18px; color:#FFE047;}
.sodium-actual-ten svg {font-weight:800; font-size:18px; ; color:#FF483B;}
.sodium-actual-ok svg{font-weight:800; font-size:18px;  color:#61FFA3;}

.hide-questions{display:none;}



/* Bootstrap fixes */
.accordion{border:none !important; background:transparent !important;}
.accordion-button:not(.collapsed){background:#F4F4F4 !important; color:#000;}
.accordion-button{background-color:#F4F4F4 !important; color:#000 !important; font-weight:bold; border:none !important; border-radius:0px !important;}
.loading-gif{float:left; }
.loading-gif img{
width:80px;
}
button, .app-button{
  padding:15px 30px;
  color:#ffffff;
  background-color:#e8d1cc;
  border:none;
  font-size:18px;
  margin:10px;
}

.btn-primary {
  background-color:#063d3f !important;
  border:none !important;
  padding:8px 20px !important;
  font-size:18px !important;
  margin:0 !important;
}

.btn-primary:hover{
  border:none !important;
}

label {
  font-weight: 700;
  font-size:18px;
  margin-bottom:5px;
}
input{padding:12px 10px;}

.signOutButton{font-size:16px; padding:10px 20px; float:right; color:#063d3f; background:#f79478; border-radius:40px;}

.App {
  text-align: center;
}

.app-ui .logo{width:20%;}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  width:40%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #063d3f;
  background-position:center center;
  background-size:contain;
  min-height: 10vh;
  padding:20px 20px;
  color: white;

}

.App-header .header-inner{margin:0 auto; max-width:1300px;}

.App-header .logo img{max-width:300px;}

.app-body{
  background-color:#fff;
  width:100%;
  text-align:center;
}

.pageContainer{
  min-height:80vh;
}

footer{
  background:#f7f7f7;
  border-top:solid thin #d2dbe2;
  width:100%;
}
footer a{color:#063d3f; font-weight:700; }

.footer-inner{width:1300px; text-align:center; margin:0 auto;  margin-top:10px; width:70%;}

.terms{}
.terms li{list-style-type:none; display:inline; margin-right:20px;}
.footer1{text-align:left;}
.footer2{text-align:right; font-weight:700; color:#063d3f;}
.login-container, .signup-container, .reset-container{
  margin:40px auto;
  text-align:left;
  width:500px;
  background:#fff;
  padding:10px 0;
  border-radius:30px;
  border:solid 3px #d2dbe2;

}
.login-tabs, .reset-tab{
  width:100%;
  text-align:center;
  font-size:20px;
  font-weight:bold;
  text-align:left;
}

.signup-container, .reset-container{
  padding:40px;
}

.react-tabs__tab{color:#58beb8; padding:10px 50px !important; border-bottom:solid 3px #e5e5e5!important; border-left:solid 3px #e5e5e5!important;}
.react-tabs__tab--selected{color:#58beb8 !important; border:none !important; border-right: solid 3px #e5e5e5;}
.react-tabs__tab-list{border:none!important;}
.react-tabs__tab-panel{padding:40px;}
.react-tabs__tab-panel--selected{}

.login-container input[type=text],
.signup-container input[type=text],
.reset-container input[type=text],
.login-container input[type=password],
.signup-container input[type=password],
.login-container input[type=email],
.signup-container input[type=email]
{
  padding:14px 20px;
  border-radius:40px;
  border:none;
  width:100%;
  background-color:#d2dbe2 !important;
}

.password-toggle{width:100%;}
.password-toggle-icon{float:right; margin-top:-45px; margin-right:15px; position:relative; z-index:9999; cursor:pointer}

.login-button{
  padding:15px 30px;
  border-radius:40px;
  color:#063d3f;
  font-weight:700;
  background-color:#f79478;
  border:none;
  font-size:18px;
  margin:10px 0;
  width:100%;
}

.signup-button{border-radius:40px; margin-top:20px;}

.forgot-pass{
  width:100%;
  text-align:right;
  margin:40px 20px 0 20px;
}
.forgot-pass span{
  margin-right:40px;
}
.forgot-pass span a{font-size:18px; color:#063d3f; font-weight:600;}

.App-link {
  color: #61dafb;
}
.register{width:100%; text-align:center;}
.register span a{color:#063d3f;}


/* ------------------Dashboard
*/

.nav-container{
  width:100%;
  background-color:#e8d1cc;
  min-height:80px;
  list-style-type:none;
  text-align:left;
}


.client-dash-nav{
  margin:0 auto;
  text-align:left;
  font-size:1.3rem;
  padding:20px;
  color:#063d3f;
  font-weight:600;
}

.client-dash-nav .row .col{text-align:center;}

.dash-module-content .accordion-header button{font-size:24px; background-color:#F4F4F4 !important;}
.dash-module-content .accordion-item{padding:0; margin:0;}
.dash-module-content .accordion-body{padding:20px 0 0 0;}

.dropdown-basic{background-color:transparent !important;}
.dropdown-toggle {background:transparent !important; color:#063d3f !important; font-size:20px !important; padding:0 !important; font-weight:600 !important;}


.client-dash-nav a{
  color:#063d3f;
  text-decoration:none;
}

.client-dash-nav a:hover{
  color:#063d3f;
  text-decoration:underline;
}

.client-dash-container{
  width:100%;
  margin:0 auto;

}
.client-dash-modules{
  width:100%;
  margin:0 auto;
  padding:100px 0;
}
.dash-module-single{
  width:100%;
  margin-bottom:20px;
}

.dash-module-content div{font-weight:bold; font-size:22px; padding:0 20px;}

.dash-module-title-container{
  border-radius:30px 30px 0 0;
  background:#e5e5e5;
  border:solid 3px #e5e5e5;
  width:100%;
  text-align:center;
  padding:20px 20px;
}
.dash-module-title{
  display:inline;
  vertical-align:middle;
  font-size:1.4rem;
  color:#58beb8;
  font-weight:600;

}

.dash-module-content{
  border-left:solid 3px #e5e5e5;
  border-right:solid 3px #e5e5e5;
  border-bottom:solid 3px #e5e5e5;
  border-radius:0 0 20px 20px;
  padding:60px 20px;
}

.client-dash-modules ul li{list-style-type:none;}


.dash-modules{border-radius:20px; border:solid 3px #e5e5e5;}
.dash-modules .dash-module-title{
  margin:20px 0;
  color:#58beb8;
  font-size:20px;
  border-bottom:solid 3px #e5e5e5;}
.dash-modules ul{padding:0; margin:0;}
.dash-modules ul li{list-style-type:none; border-bottom:solid 3px #e5e5e5;}

.dash-current-product-title{font-size:18px !important; font-weight:600 !important;}
.dash-current-products{font-size:18px !important; font-weight:600 !important;}
.dash-task-gut-training{color:#58beb8;}

/*----------- My Account -- */
.myAccountContainer, .sweatTestContainer, .myRacePlanContainer, .gutTrainingContainer, .gutSingleContainer, .myRacePlanContainer, .resourcesContainer, .privacyContainer{
  margin:0 auto;
  width:70%;
  padding:100px 0;

}

.pricing-plan-container{
  width:100%;
}
.pricing-plans-single-container{
  background:#063d3f;
  color:#fff;
  border:solid 3px #e5e5e5;
  border-radius:30px;
  padding:30px;
  margin:0 10px;
}

.pricing-plan-description{
  background:#fff;
  color:#000;

  min-height:200px;
  border-radius:30px;
  text-align:center;
}
.pricing-plan-price{
  font-size:38px;
  font-weight:bold;
  color:#e8d1cc;
}
.manage-subscription-container a button{background:#063D3F;color:#fff;}

.productContainer, sweat-test-table-container, sweatTestTable, .sweatTableContainer{overflow-x:scroll;}
.sweat-test-table-container, .add-new-session-container, .gut-training-single, .gut-training-single-single, .race-plan-container, .resources-inner {
  padding:20px 40px;
  text-align:left;
  border:solid 3px #d2dbe2;
  border-radius:20px;
  margin:20px 0;
}

.sweat-test-add-session button, .new-session-submit, .in-progress, .gut-training-add-session button, .race-plan-add-session button{
  border-radius:300px;
  color:#000;
  font-weight:700;
  background-color:#f79478;
  padding:8px 30px;
  margin:10px 0;
}

.gut-training-single-single{overflow-x:scroll;}

new-session-submit{
  float:left;
}

.gutForm select, .gutForm input, .racePlan select, .racePlan input{padding:10px 20px; background:#d2dbe2; border-radius:300px; width:300px;}
.gut-values{padding:60px 0;}

.add-new-session-container .col input{width:100%; border-radius:300px; border:none; background-color:#d2dbe2; font-weight:500;}
.add-new-session-container .col select{padding:14px 5px; width:100%; border-radius:300px; border:none; background-color:#d2dbe2; font-weight:500;}

.warning-icon svg{color:yellow; width:30px; cursor:zoom-in;}
.warning{color:red; margin:0 20px;}
.critical-icon svg{color:red; width:30px; cursor:zoom-in;}
.goal-suggestion{display:none;}

.sodium-icon, .carb-icon{cursor:zoom-in;}

.finished-gut{text-align:right; margin-bottom:20px;}

.module-title{
  font-size:20px;
  color:#58beb8;
  width:100%;
  border-bottom:solid thin #d2dbe2;
  padding-bottom:20px;
}

.product-col{max-width:60px;}
.race-product-col{max-width:53px;padding:10px;}
.race-product-col input{}
.race-product-td{min-width:150px; width:150px;}

.table-product{padding:10px; border-radius:300px; font-size:18px; font-weight:500; background:#e5e5e5;}

#gut-details-view td input{width:80px !important;}

/*------------- Sweat Calc --- */
.sweatTable th{font-size:12px;}

.hide-est, .start-est{display:none;}
.show-est{display:block}

.plus-minus-icon{padding:4px; color:#063D3F; font-size:20px; font-weight:bold; cursor:pointer;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*--------------------------------------------
----------------------------------------------
--------------  Gut Training -----------------
--------------------------------------------*/

.module-date{margin-left:20px;}

.remove-prod-button:hover{
  background:#f79378;
}

.user-edit{text-align:right;}


.remove-product{text-decoration:none;}

.red-highlight{background-color:#f79378 !important; font-weight:700;}

.gut-details-title h2{padding:0 20px;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.quick-add-product{float:right;}

.product-type{width:100%; height:50px;}

.week-save{padding:3px; border-radius:10px;}



/*--------------------------------------------
----------------------------------------------
--------------  Sweat Testing -----------------
--------------------------------------------*/

.sweat-remove svg{font-size:22px; color:#eccccf;}
.sweat-remove svg:hover{color:#ecccc2;}


input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.remove-container{text-align:right; width:100%;}
.remove-res{cursor:pointer; color:#063d3f;}
#resource-content{width:100%; min-height:200px;}

/* Suggestion Form*/
.suggest-product-container{margin:0 auto; width:80%; text-align:center; padding:100px 0;}
.suggest-product-container form{width:600px; background:#f7f7f7; margin:0 auto; border-radius:20px; padding:30px;}
.suggest-product-container form input, .suggest-product-container form select{width:400px; margin-bottom:30px; background:#f5c2c7; padding:10px;}

.suggest-product-container form button{background:#063D3F; color:#fff;}

.manage-subscription-container button{background:#2074D4;color:#fff; border-radius:5px; font-weight:600;}
.pricing-plans-container{padding:30px 0;}
.subscription-plans{padding:80px 0; text-align:center;}
.subscriptions-inner{
  align-items: stretch;
  grid-template-columns: repeat(3,1fr);
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.subscription-single{display:flex; flex-direction:column;
    align-items:stretch;
    flex-grow:1;
  width:400px;
  border:solid 4px #333;
  border-radius:8px;
  min-height:300px;
  padding:40px;
  text-align:center;
  color:#063d3f;
  justify-content:stretch;
}
.green-button{background:#063d3f}

/*  for gut form

    padding: 10px;
    background: #E8D1CC;
    color: #000;

 */

.myRacePlanContainer .row{margin-top:20px;}
.current-prod{background:#e5e5e5; padding:20px;}




@media only screen and (max-width: 780px) {
  .client-dash-nav .d-flex{}
  .login-container, .signup-container, .reset-container{
    width:100%;
  }
  .footer-inner{width:100%; padding:0 20px}
  .footer-inner ul{padding:0;}

  .client-dash-modules{padding:20px 0;}

  .dash-module-single{width:100%; display:block;}
  .gutTrainingContainer, .myRacePlanContainer, .gutSingleContainer{width:90%;}
  .in-progress{padding:10px;}
  .client-dash-nav{font-size:16px;}
  .client-dash-nav #dropdown-basic{font-size:16px !important;}
  .dtfc-fixed-left{position:relative !important;}
  .row-cols-2>*{width:100% !important; margin:10px 0;}
  .h-100{height:auto !important;}
  .sweatTestContainer, .myRacePlanContainer, .gutSingleContainer{width:100%; padding:20px 2%}
  .gut-training-single-single{padding:40px 10px;}
  .gutForm select, .gutForm input, .racePlan select, .racePlan input{width:100%;}
  .logo{width:100%;}
  .client-dash-nav .nav-text{display:none;}
  .sweat-test-table-container, .add-new-session-container, .gut-training-single, .gut-training-single-single, .race-plan-container, .resources-inner{padding:10px 20px;}
  .col-xs-6{width:48% !important;}
}
